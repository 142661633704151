import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Ласкаво просимо до Creatix Agency
			</title>
			<meta name={"description"} content={"В агентстві Creatix ми прагнемо зрозуміти ваше унікальне бачення та перетворити його на переконливі маркетингові стратегії, які говорять багато про що."} />
			<meta property={"og:title"} content={"Ласкаво просимо до Creatix Agency"} />
			<meta property={"og:description"} content={"В агентстві Creatix ми прагнемо зрозуміти ваше унікальне бачення та перетворити його на переконливі маркетингові стратегії, які говорять багато про що."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66583b441d712a00233449af/images/1-1%20%281%29.jpg?v=2024-05-30T11:03:45.343Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65dde651926d910020ed4648"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});